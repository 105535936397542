import { css, cx } from "@emotion/css";
import { themeTokens, space, text } from "@octopusdeploy/design-system-tokens";
import type { ReactElement, ReactNode } from "react";
import React from "react";
import { useIsLargerThanIpadResolution, useIsLegacySmallScreen } from "../../hooks";
import type { BreadcrumbItem } from "../Breadcrumbs";
import { Callout, type CalloutType } from "../Callout/Callout";
import type { SimpleMenuItem } from "../MenuItems/SimpleMenuItems";
import type { PageAction, PrimaryPageAction } from "../PageActions/PageActions";
import { PageHeaderPrimary } from "../PageHeaderPrimary";
import { LinearProgress } from "../Progress";
import { AdvancedFilters } from "./AdvancedFilters";
import { ErrorPanel, type ErrorInfo } from "./ErrorPanel";
import { Filters, FiltersSummary } from "./Filters";
import { useAdvancedFiltersVisibility } from "./useAdvancedFiltersVisibility";
interface Level1PageLayoutBaseProps {
    /**
     * Defines the elements used in the page header, ensuring a consistent and structured layout.
     * The `title` is required, while other elements such as chips, breadcrumbs, and actions are optional.
     *
     * - **title**: `string` *(required)*
     * The main title of the page. This is a required field and serves as the primary header text.
     * - **chip**: `ReactElement` *(optional)*
     * A custom chip component to display alongside the title, often used for status or categorization. Refer to the Chip component.
     * - **breadcrumbs**: `BreadcrumbItem[]` *(optional)*
     * An array of breadcrumb items for hierarchical navigation. Refer to the the Breadcumbs component.
     * - **primaryAction**: `PrimaryPageAction` *(optional)*
     * Defines the primary action for the page, such as a button for a key task.
     * Example: `{ label: "Save", onClick: () => handleSave() }`.
     * - **pageActions**: `PageAction[]` *(optional)*
     * An array of secondary actions displayed on the page, typically as buttons.
     * Example: `{ label: "Edit", type: "Button", buttonType: "secondary", onClick: () => handleEdit() }`.
     * - **overflowActions**: `SimpleMenuItem[]` *(optional)*
     * Actions displayed in an overflow menu for additional functionality. Refer to the the Simple Menu Items component.
     */
    header: {
        title: string;
        chip?: ReactElement;
        breadcrumbs?: BreadcrumbItem[];
        primaryAction?: PrimaryPageAction;
        pageActions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
        pageLayoutV2?: boolean;
    };
    /**
     * The `Callout` component displays important information or alerts to the user.
     * It supports two configurations: a custom callout with a React element as content,
     * or a standard callout with a title, content, and optional close functionality.
     *
     * **Custom Callout**
     * - **type**: `"custom"`
     * Specifies the callout type as "custom".
     * - **content**: `React.ReactElement`
     * A custom React element to render inside the callout.
     *
     * **Standard Callout**
     * - **type**: `"information", "success", "warning", "danger", "new-feature", "generic"`
     * The type of the callout, defining its style and purpose.
     * - **title**: `React.ReactNode` *(optional)*
     * The optional title of the callout.
     * - **content**: `React.ReactNode`
     * The main content or message of the callout.
     * - **onClose**: `() => void` *(optional)*
     * A callback function triggered when the callout is closed.
     */
    callout?: Level1PageLayoutCalloutType;
    busy?: boolean;
    errors?: ErrorInfo[];
    /** Temporary Prop to handle moving padding to pages as part of Level 1 Page Layout modifications by FFT */
    pageLayoutV2?: boolean;
}
export type Level1PageLayoutCalloutType = {
    type: "custom";
    content: React.ReactElement;
} | {
    title?: ReactNode;
    content: ReactNode;
    type: CalloutType;
    onClose?: () => void;
};
interface ContentProps {
    /**
     * Defines the structure for filter inputs, summaries, and advanced filtering options in a page layout.
     * - **inputs**: `React.ReactNode[]` *(required)*
     * An array of input elements for filters. These can include dropdowns, text inputs, or custom filter components.
     *
     * - **filtersSummary**: `React.ReactNode` *(optional)*
     * A summary or overview of the applied filters, typically displayed above or near the filter inputs.
     *
     * - **advancedFilters**: `object` *(optional)*
     * Advanced filtering options for more complex use cases:
     * -- **content**: `React.ReactNode`
     * Custom content for the advanced filters section, such as additional inputs or filter groups.
     * -- **onResetFilter**: `() => void`
     * A callback function to reset the advanced filters to their default state.
     * -- **hasUserSelectedValues**: `boolean`
     * Indicates whether the user has applied any values in the advanced filters.
     */
    filters?: {
        inputs: ReactNode[];
        filtersSummary?: ReactNode;
        advancedFilters?: {
            content: ReactNode;
            onResetFilter: () => void;
            hasUserSelectedValues: boolean;
        };
    };
    /**
     * Defines the UI and placement options for pagination controls within a page layout.
     *
     * - **ui**: `React.ReactNode` *(required)*
     * The React node representing the pagination UI, such as a component for navigating through pages.
     * - **placement**: `"top" | "bottom" | "topAndBottom"` *(required)*
     * Specifies where the pagination controls are displayed on the page:
     * - `"top"`: Displays pagination controls at the top of the page.
     * - `"bottom"`: Displays pagination controls at the bottom of the page.
     * - `"topAndBottom"`: Displays pagination controls at both the top and bottom of the page.
     */
    pagination?: {
        ui: ReactNode;
        placement: "top" | "bottom" | "topAndBottom";
    };
}
type Level1PageLayoutPropsWithoutTabs = Level1PageLayoutBaseProps & ContentProps & {
    children: React.ReactNode;
};
export type Level1PageLayoutProps = Level1PageLayoutPropsWithoutTabs;
/**
 * The `Level1PageLayout` component has pre-determined slots for `Errors`, `Callout`, `Header`, `Pagination`, and `Filters`.
 * It is designed to provide a consistent layout for pages with a single content area and optional filters and pagination controls.
 */
export function Level1PageLayout({ pageLayoutV2 = false, header, busy = false, callout, errors, ...contentProps }: Level1PageLayoutProps) {
    const content = contentProps.children;
    const filters = contentProps.filters;
    const pagination = contentProps.pagination;
    const isSmallScreen = useIsLegacySmallScreen();
    const isLargerThanIPad = useIsLargerThanIpadResolution();
    const [isAdvancedFiltersVisible, setIsAdvancedFiltersVisible] = useAdvancedFiltersVisibility(filters?.advancedFilters);
    const paginationAtBottom = pagination && pagination.placement !== "top";
    const paginationAtTop = pagination && pagination.placement !== "bottom";
    const hasAdvancedFilters = filters?.advancedFilters !== undefined;
    const hasFilters = (filters?.inputs && filters.inputs.length > 0) || hasAdvancedFilters;
    const hasFilterAndPaginationSection = hasFilters || filters?.filtersSummary !== undefined || paginationAtTop;
    const getContainerClassName = (isLargerThanIPad: boolean, PageLayoutV2: boolean) => {
        if (isLargerThanIPad) {
            return PageLayoutV2 ? pageContentStyles.largeBottomPaddingContainer : pageContentStyles.largePaddingContainer;
        }
        else {
            return PageLayoutV2 ? pageContentStyles.smallBottomPaddingContainer : pageContentStyles.smallPaddingContainer;
        }
    };
    return (<div className={cx(pageContentStyles.container, { [pageContentStyles.containerHasPadding]: pageLayoutV2, [pageContentStyles.containerHasPaddingMobile]: !isLargerThanIPad })}>
            {/* Progress should be at the top of the page, but to avoid breaking things we need to conditionally render it
            until them updates to the designs are complete */}
            {pageLayoutV2 && (<div className={pageContentStyles.progressBarContainer}>
                    <LinearProgress variant={"indeterminate"} show={busy}/>
                </div>)}
            <PageHeaderPrimary pageLayoutV2={header.pageLayoutV2} breadcrumbs={header.breadcrumbs} title={header.title} titleChips={header.chip ? [header.chip] : undefined} primaryAction={header.primaryAction} actions={header.pageActions} overflowActions={header.overflowActions}/>
            <div className={getContainerClassName(isLargerThanIPad, pageLayoutV2)}>
                {/* Progress should be at the top of the page, but to avoid breaking things we need to conditionally render it
        until them updates to the designs are complete - once FFT have finished we can remove the duplicate */}
                {!pageLayoutV2 && (<div className={pageContentStyles.progressBarContainer}>
                        <LinearProgress variant={"indeterminate"} show={busy}/>
                    </div>)}
                {errors?.map((error, index) => (<ErrorPanel key={index} error={error}/>))}
                {callout &&
            (callout.type === "custom" ? (callout.content) : (<Callout type={callout.type} title={callout.title} hideTitle={!callout.title} onClose={callout.onClose} canClose={!!callout.onClose}>
                            {callout.content}
                        </Callout>))}
                {hasFilterAndPaginationSection && (<div className={pageContentStyles.filtersAndPaginationSection}>
                        <div className={pageContentStyles.filtersAndPaginationRow}>
                            {hasFilters && <Filters inputs={filters.inputs} showAdvancedFiltersToggle={hasAdvancedFilters} isAdvancedFiltersVisible={isAdvancedFiltersVisible} onAdvancedFiltersVisibilityChanged={setIsAdvancedFiltersVisible}/>}
                            {paginationAtTop && pagination.ui}
                        </div>
                        {filters?.filtersSummary && <FiltersSummary summary={filters.filtersSummary}/>}
                    </div>)}
                <div className={cx(pageContentStyles.sectionsWithSidebars, {
            [pageContentStyles.sectionsWithSidebarsVertical]: isSmallScreen,
            [pageContentStyles.sectionsWithSidebarsNoMargin]: pageLayoutV2,
        })}>
                    {filters?.advancedFilters && isAdvancedFiltersVisible && (<AdvancedFilters content={filters.advancedFilters.content} onResetFilter={filters.advancedFilters.onResetFilter} isResetEnabled={filters.advancedFilters.hasUserSelectedValues} parentOrientation={isSmallScreen ? "column" : "row"}/>)}
                    <div className={pageContentStyles.contentBox}>{content}</div>
                </div>
                {paginationAtBottom && <div className={pageContentStyles.footerPaginationSection}>{pagination.ui}</div>}
            </div>
        </div>);
}
const pageContentStyles = {
    container: css({
        display: "flex",
        position: "relative",
        flexDirection: "column",
        font: text.regular.default.medium,
    }),
    smallPaddingContainer: css({
        padding: `0 ${space[16]} ${space[16]}`,
    }),
    largePaddingContainer: css({
        padding: `0 ${space[32]} ${space[32]}`,
    }),
    // Padding is being shifted to the container
    // as a result these values have to change
    // it's currently conditional while the work is
    // in progress
    containerHasPadding: css({
        margin: space[32],
    }),
    containerHasPaddingMobile: css({
        margin: space[16],
    }),
    smallBottomPaddingContainer: css({
        padding: 0, // Can be removed when conditionals are removed
        paddingBottom: space[16],
    }),
    largeBottomPaddingContainer: css({
        padding: 0, // Can be removed when conditionals are removed
        paddingBottom: space[32],
    }),
    progressBarContainer: css({
        paddingBottom: space[8],
        position: "absolute",
        width: "100%",
    }),
    filtersAndPaginationSection: css({
        gap: space[8],
    }),
    filtersAndPaginationRow: css({
        display: "flex",
        justifyContent: "space-between",
    }),
    sectionsWithSidebars: css({
        display: "flex",
        flex: 1,
        color: themeTokens.color.text.primary,
        marginTop: space[24],
    }),
    // Temporary while FFT updates Level 1 Pages. This will be removed (along with the marginTop above) once the padding is moved to the container
    sectionsWithSidebarsNoMargin: css({
        marginTop: 0,
    }),
    sectionsWithSidebarsVertical: css({
        flexDirection: "column",
    }),
    contentBox: css({
        flex: 1,
        minWidth: 0,
    }),
    footerPaginationSection: css({
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: space[16],
    }),
};
